@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Montserrat", system-ui, sans-serif;
    font-size: 16px;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }

  :root {
    /* **text colors** */
    /* color: #000; */
    --color-text-primary: 0 0 0;
    --color-text-primary-inverted: 255 255 255;
    /* color: #B07B03; */
    --color-text-link: 176 123 3;

    /* **background colors** */
    /* color: #FFF; */
    --color-base: 255 255 255;
    --color-base-hover: 243 244 246;
    /* color: #F5F4F0; */
    --color-primary: 245 244 240;
    --primary-inverted: 89 85 81;
    /* color: #FDB824; */
    --color-button-accent: 253 184 36;
    /* color: #fdb8240d; */
    --color-unassigned: 253, 184, 36, 0.05;
    /* color: #EEF1F6; */
    --color-surface-soft: 238 241 246;
    /* color: #F5F4F0; */
    --color-surface-hard: 245 244 240;

    /* **fill svg colors** */
    /* color: #595959; */
    --color-fill-base: 89 89 89;
    /* color: #000; */
    --color-fill-active: 0 0 0;
    /* color: #1C1B1F; */
    --color-fill-primary: 28 27 31;
  }

  .dark {
    /* **text colors** */
    /* color: #FFF; */
    --color-text-primary: 255 255 255;
    --color-text-primary-inverted: 0 0 0;
    /* color: #FEEABD; LL note: O S J tabs font*/
    --color-text-link: 254 234 189;

    /* **background colors** */
    /* color: #737373; */
    --color-base: 115 115 115;
    --color-base-hover: 141 141 141;
    /* color: #595551; */
    --color-primary: 89 85 81;
    --primary-inverted: 245 244 240;
    /* color: #404040; LL note: unassigned column background*/
    --color-unassigned: 64, 64, 64, 1;
    /* color: #42527B; LL note: soft cell background*/
    --color-surface-soft: 66 82 123;
    /* color: #595551; LL note: hard cell background*/
    --color-surface-hard: 89 85 81;

    /* **fill svg colors** */
    /* color: #FFF; */
    --color-fill-base: 255 255 255;
    /* color: #FFF; */
    --color-fill-active: 255 255 255;
    /* color: #FFF; */
    --color-fill-primary: 255 255 255;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
}

.arrow::before {
  display: block;
  content: ">";
  width: 1.5rem;
  height: inherit;
  text-align: center;
  transform: scaleY(1.9);
}
.arrow-down::before {
  transform: rotate(90deg) scaleY(1.9);
}
.arrow-up::before {
  content: "<";
}

.arrow-right::before {
  content: "<";
}

.arrow-small::before {
  font-size: x-small;
  width: 1rem;
}

.badge {
  @apply min-w-2 rounded;
}

select {
  appearance: none;
  outline: none;
  background-image: url(assets/icons/arrow-down.svg);
  background-position: right 4px center;
  background-repeat: no-repeat;
  padding-right: 1.8rem;
  padding-left: 0.5rem;
  height: 1.5rem;
}

select.arrow-link {
  background-image: url(assets/icons/arrow-down-link.svg);
}

button:disabled,
button[disabled] {
  @apply opacity-50;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  height: 23px;
  width: 24px;
}

/* styling scroll */
:root {
  scroll-margin: 1px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5bd;
}

::-webkit-scrollbar-thumb {
  background-color: #919090;
  border-radius: 20px;
  border: transparent;
}
